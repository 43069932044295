import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    return (
        <div className='bg-delft rounded-md'>
            <div className='pt-3 ps-4'>
                <h1 className='text-2xl text-columbia'>Popular artists</h1>
            </div>
            <div className='flex flex-wrap justify-center md:justify-start md:ml-5'>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/DarshanRaval.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Darshan Raval</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/ArijitSingh.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Arijit Singh</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md' onClick={() => navigate('/songs')}>
                    <img src='/shreya.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Shreya Ghoshal</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/JubinNautiyal.jpeg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Jubin Nautiyal</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/B-Praak.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>B Praak</h1>
                </button>
            </div>
            <div className='ps-4'>
                <h1 className='text-2xl text-columbia'>Category</h1>
            </div>
            <div className='flex flex-wrap justify-center md:justify-start md:ml-5'>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/Spiritual.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Spiritual</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/New.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>New Releases</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/Hindi.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Hindi</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/Chill.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Chill</h1>
                </button>
                <button className='h-48 w-44 bg-lightDelft mx-5 my-3 rounded-md '>
                    <img src='/Party.jpg' alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
                    <h1 className='text-xl text-center text-columbia'>Party</h1>
                </button>
            </div>
        </div>
    );
}

export default Home;
