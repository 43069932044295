import React from 'react'
import Card from './Card'

function Category() {
    return (
        <>
            <div className='bg-delft rounded-md'>
                <div className='pt-5 ps-4'>
                    <h1 className='text-2xl text-columbia'>Category</h1>
                </div>
                <div className='flex flex-wrap justify-center md:justify-start md:ml-5'>
                    <Card name={'Spiritual'} img={'/Spiritual.jpg'} />
                    <Card name={'New Releases'} img={'/New.jpg'} />
                    <Card name={'Hindi'} img={'/Hindi.jpg'} />
                    <Card name={'Chill'} img={'/Chill.jpg'} />
                    <Card name={'Party'} img={'/Party.jpg'} />
                    <Card name={'90s Hit'} img={'/90s.png'} />
                </div>
            </div>
        </>
    )
}

export default Category