import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <div className='w-full min-h-screen flex flex-col md:flex-row bg-cadet'>
            <div className='w-full md:w-72 md:mr-3'>
                <Sidebar />
            </div>
            <div className='w-full flex flex-col'>
                <div className='h-14 mb-3'>
                    <Navbar />
                </div>
                <div className='flex-1 overflow-y-auto'>
                    {children}
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Layout;
// import React from 'react'
// import Sidebar from './Sidebar'
// import Navbar from './Navbar'
// import Footer from './Footer'

// const Layout = ({ children }) => {
//     return (
//         <div className='w-full h-screen flex bg-cadet'>
//             <div className='w-72 mr-3'>
//                 <Sidebar />
//             </div>
//             <div className='w-full flex flex-col'>
//                 <div className='h-14 mb-3'>
//                     <Navbar />
//                 </div>
//                 <div className='h-screen'>
//                     {children}
//                 </div>
//                 <Footer />
//             </div>
//         </div>)
// }

// export default Layout
