import React from 'react';

function Songs() {
    return (
        <div className='m-5'>
            <div className='flex flex-col md:flex-row items-center md:space-x-5'>
                <img alt='' src='./shreya.jpg' className='h-40 w-40 mb-5 md:mb-0' />
                <div className='text-center md:text-left'>
                    <h5 className='text-columbia text-lg'>Playlist</h5>
                    <h1 className='text-columbia text-3xl md:text-7xl pt-3'>Shreya Ghoshal</h1>
                </div>
            </div>
            <div className='flex justify-center md:justify-start space-x-5 my-5'>
                <img alt='' src='./play.png' className='h-12' />
                <img alt='' src='./heart.png' className='h-8 m-2' />
            </div>
            <div className='overflow-x-auto'>
                <table className='w-full text-columbia'>
                    <thead>
                        <tr className='border-b'>
                            <th className='text-2xl'>#</th>
                            <th className='text-2xl'>Title</th>
                            <th className='text-2xl'>Album</th>
                            <th><img alt='' src='./time.png' className='h-6' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='pt-4'>1</td>
                            <td className='flex pt-4 items-center'>
                                <img alt='' src='krishna1.png' className='h-8 border rounded-md mr-2 ' />
                                Nand ka lala bansuri wala
                            </td>
                            <td className='pt-4'>Bhajan Masterpieces</td>
                            <td className='pt-4'>6:41</td>
                        </tr>
                        <tr>
                            <td className='pt-4'>2</td>
                            <td className='flex pt-4 items-center'>
                                <img alt='' src='2.jpg' className='h-8 border rounded-md mr-2' />
                                Papa Mere Papa<br />
                            </td>
                            <td className='pt-4'>-</td>
                            <td className='pt-4'>4:23</td>
                        </tr>
                        <tr>
                            <td className='pt-4'>3</td>
                            <td className='flex pt-4 items-center'>
                                <img alt='' src='3.jpg' className='h-8 border rounded-md mr-2' />
                                Dhaagon Se Baandhaa
                            </td>
                            <td className='pt-4'>-</td>
                            <td className='pt-4'>2:48</td>
                        </tr>
                        <tr>
                            <td className='pt-4'>4</td>
                            <td className='flex pt-4 items-center'>
                                <img alt='' src='4.png' className='h-8 border rounded-md mr-2' />
                                KAR HAR MAIDAAN FATEH
                            </td>
                            <td className='pt-4'>-</td>
                            <td className='pt-4'>5:31</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Songs;

// import React from 'react'

// function Songs() {
//     return (
//         <>
//             <div className='flex m-5'>
//                 <img alt='' src='./shreya.jpg' className='h-40 w-40' />
//                 <div className='pl-5'>
//                     <h5 className='text-columbia text-lg'>Playlist</h5>
//                     <h1 className='text-columbia text-7xl pt-3'>Shreya Ghoshal</h1>
//                 </div>
//             </div>
//             <div className='flex m-5 space-x-5'>
//                 <img alt='' src='./play.png' className='h-12' />
//                 <img alt='' src='./heart.png' className='h-8 m-2' />
//             </div>
//             <table className='w-full text-columbia'>
//                 <thead>
//                     <tr className='border-b '>
//                         <td className='text-2xl '>#</td>
//                         <td className='text-2xl '>Title</td>
//                         <td className='text-2xl '>Album</td>
//                         <td><img alt='' src='./time.png' className='h-6' /></td>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td className='pt-4' >1</td>
//                         <td className='flex pt-4'>
//                             <img alt='' src='krishna1.png' className='h-8 border rounded-md mr-2' />
//                             Nand ka lala bansuri wala
//                         </td>
//                         <td className='pt-4'>Bhajan Masterpieces</td>
//                         <td className='pt-4'>6:41</td>
//                     </tr>
//                     <tr>
//                         <td className='pt-4'>2</td>
//                         <td className='flex pt-4'>
//                             <img alt='' src='2.jpg' className='h-8 border rounded-md mr-2' />
//                             Papa Mere Papa<br />
//                         </td>
//                         <td className='pt-4'>-</td>
//                         <td className='pt-4'>4:23</td>
//                     </tr>
//                     <tr>
//                         <td className='pt-4'>3</td>
//                         <td className='flex pt-4'>
//                             <img alt='' src='3.jpg' className='h-8 border rounded-md mr-2' />
//                             Dhaagon Se Baandhaa
//                         </td>
//                         <td className='pt-4'>-</td>
//                         <td className='pt-4'>2:48</td>
//                     </tr>
//                     <tr>
//                         <td className='pt-4'>4</td>
//                         <td className='flex pt-4'>
//                             <img alt='' src='4.png' className='h-8 border rounded-md mr-2' />
//                             KAR HAR MAIDAAN FATEH
//                         </td>
//                         <td className='pt-4'>-</td>
//                         <td className='pt-4'>5:31</td>
//                     </tr>
//                 </tbody>
//             </table>
//         </>
//     )
// }
// export default Songs

// {/* <div className='flex ms-5 '> */ }
// {/* </div> */ }
// {/* <div className='grid grid-cols-4 ms-5'>
//                 <h1 className='text-columbia'>#</h1>
//                 <h1 className='text-columbia '>Title</h1>
//                 <h1 className='text-columbia '>Album</h1>
//                 <img alt='' src='./time.png' className='h-5' />
//             </div>
//             <div className='flex ms-5'>
//                 <h1 className='text-columbia'>1</h1>
//                 <h1 className='text-columbia'>Nand Ka Lala Bansuri Wala</h1>
//             </div> */}