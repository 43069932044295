import React from 'react'
import { useNavigate } from 'react-router-dom';

function Card({ img, name, url }) {
    const navigate = useNavigate();
    return (
        <button className='h-48 w-44 bg-lightDelft m-5 rounded-md' onClick={() => navigate(url)}>
            <img src={img} alt='' className='h-28 w-28 rounded-md my-5 mx-8' />
            <h1 className='text-xl text-center text-columbia'>{name}</h1>
        </button>
    )
}

export default Card