import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Login'
import SignUp from './SignUp'
import Artists from './Components/Artists'
import Home from './Components/Home'
import Search from './Components/Search'
import Songs from './Components/Songs'
import Layout from './Components/Layout'
import Category from './Components/Category'
// import AIPlaylist from './Components/AIPlaylist'

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/' element={<Layout><Home /></Layout>} />
                    <Route path='/songs' element={<Layout><Songs /></Layout>} />
                    <Route path='/search' element={<Layout><Search /></Layout>} />
                    <Route path='/artists' element={<Layout><Artists /></Layout>} />
                    <Route path='/category' element={<Layout><Category /></Layout>} />
                </Routes>

            </BrowserRouter>
            {/* <AIPlaylist /> */}
        </>
    )
}

export default App