import React from 'react';

const Search = () => {
    return (
        <div className='bg-delft rounded-md p-2 min-h-96'>
            <h1 className='text-3xl text-columbia mb-2'>Browse All</h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                <div className='bg-lightDelft rounded-md'>
                    <h1 className='text-2xl px-3 pt-2 text-columbia'>New Releases</h1>
                    <img alt='' src='./New.jpg' className='h-40 mx-auto my-5 px-5' />
                </div>
                <div className='bg-lightDelft rounded-md'>
                    <h1 className='text-2xl px-3 pt-2 text-columbia'>Hindi</h1>
                    <img alt='' src='./Hindi.jpg' className='h-40 mx-auto my-5 rounded-md' />
                </div>
                <div className='bg-lightDelft rounded-md'>
                    <h1 className='text-2xl px-3 pt-2 text-columbia'>Chill</h1>
                    <img alt='' src='./Chill.jpg' className='h-40 mx-auto my-5 rounded-md' />
                </div>
                <div className='bg-lightDelft rounded-md'>
                    <h1 className='text-2xl px-3 pt-2 text-columbia'>Spiritual</h1>
                    <img alt='' src='./Spiritual.jpg' className='h-40 mx-auto my-5 rounded-md' />
                </div>
                <div className='bg-lightDelft rounded-md'>
                    <h1 className='text-2xl px-3 pt-2 text-columbia'>Party</h1>
                    <img alt='' src='./Party.jpg' className='h-40 mx-auto my-5 rounded-md' />
                </div>
            </div>
        </div>
    );
};

export default Search;

// import React from 'react'

// const Search = () => {
//     return (
//         <>
//             <div className='bg-delft rounded-md'>
//                 <h1 className='text-3xl p-2 text-columbia'>Browse All</h1>
//                 <div className='flex'>
//                     <div className='h-40 w-44 bg-lightDelft rounded-md m-3'>
//                         <h1 className='text-2xl ps-3 pt-2 text-columbia'>New Releases</h1>
//                         <img alt='' src='./New.jpg' className='h-20 my-5 mx-8 ' />
//                     </div>
//                     <div className='h-40 w-44 bg-lightDelft rounded-md m-3'>
//                         <h1 className='text-2xl ps-3 pt-2 text-columbia'>Hindi</h1>
//                         <img alt='' src='./Hindi.jpg' className='h-24 my-2 mx-10' />
//                     </div>
//                     <div className='h-40 w-44 bg-lightDelft rounded-md m-3'>
//                         <h1 className='text-2xl ps-3 pt-2 text-columbia'>Chill</h1>
//                         <img alt='' src='./Chill.jpg' className='h-24 mx-10 my-2' />
//                     </div>
//                     <div className='h-40 w-44 bg-lightDelft rounded-md m-3'>
//                         <h1 className='text-2xl ps-3 pt-2 text-columbia'>Spiritual</h1>
//                         <img alt='' src='./Spiritual.jpg' className='h-24 mx-9 my-2' />
//                     </div>
//                     <div className='h-40 w-44 bg-lightDelft rounded-md m-3'>
//                         <h1 className='text-2xl ps-3 pt-2 text-columbia'>Party</h1>
//                         <img alt='' src='./Party.jpg' className='h-24 mx-10 my-2' />
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Search