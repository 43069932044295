import React, { useState } from 'react';

function Footer() {
    const [volume, setVolume] = useState(50); // Initial volume
    const [musicProgress, setMusicProgress] = useState(0); // Initial music progress

    const handleVolumeChange = (e) => {
        setVolume(e.target.value);
    };

    const handleMusicProgressChange = (e) => {
        setMusicProgress(e.target.value);
        // Here you can update the music progress based on the value of the range slider
    };

    return (
        <>
            <div className='h-16 bg-delft rounded-md flex'>
                <div className='w-full mt-2'>
                    <div className='flex justify-center space-x-5'>
                        <img alt='' src='/shuffle.png' className='h-7' />
                        <img alt='' src='/previous.png' className='h-7' />
                        <img alt='' src='/play.png' className='h-7' />
                        {/* <img alt='' src='/pause.png' className='h-7' /> */}
                        <img alt='' src='/next.png' className='h-7' />
                        <img alt='' src='/repeat.png' className='h-7' />
                    </div>
                    <div className='flex justify-center items-center space-x-2'>
                        <h1 className='text-columbia'>0:00</h1>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={musicProgress}
                            onChange={handleMusicProgressChange}
                            className="slider-thumb  appearance-none h-1 w-96 bg-columbia rounded-full outline-none"
                        />
                        <h1 className='text-columbia'>0:00</h1>
                    </div>
                </div>
                <div className='flex items-center justify-end mx-4'>
                    <img alt='' src='/volume.png' className='h-6'></img>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={volume}
                        onChange={handleVolumeChange}
                        className="appearance-none w-20 h-2  bg-columbia rounded-full outline-none"
                    />
                </div>
            </div>
        </>
    );
}

export default Footer;
