import React from 'react'
import { useNavigate } from 'react-router-dom'

function Sidebar() {

    const navigate = useNavigate()

    return (
        <div className="bg-delft h-screen py-1 rounded-md">
            <div className='bg-lightDelft m-3 px-3 py-3 rounded-xl'>
                <button className='flex items-center'>
                    <img src='/home.png' alt='' className='h-7' />
                    <h1 className='p-2 text-columbia'>Home</h1>
                </button>
                <button className='flex items-center'>
                    <img src='/singer.png' alt='' className='h-7' />
                    <h1 className='p-2 text-columbia' onClick={() => navigate('/artists')}>Artists</h1>
                </button>
                <button className='flex items-center'>
                    <img alt='' src='category.png' className='h-7' />
                    <h1 className='p-2 text-columbia' onClick={() => navigate('/category')}>Category</h1>
                </button>
                <button className='flex items-center'>
                    <img src='/search.png' alt='' className='h-7 ' />
                    <h1 className='p-2 text-columbia' onClick={() => navigate('/search')}>Search</h1>
                </button>
            </div>
            <div className='bg-lightDelft m-3 py-3 px-3 rounded-xl'>
                <div className='flex items-center'>
                    <img src='/playlist.png' alt='' className='h-7' />
                    <h1 className='p-2 text-columbia'>Your Library</h1>
                    <img src='/add.png' alt='' className='h-5 pl-8' />
                </div>
                <button className='flex items-center text-columbia'>
                    <img src='/search.png' alt='' className='h-7 my-2' />
                    Search in Your Library
                </button>
                <div className='flex items-center'>
                    <img src='/liked.png' alt='' className='h-7' />
                    <h1 className='p-2 text-columbia'>Liked Songs</h1>
                </div>
            </div>
        </div>

    )
}

export default Sidebar