import React from 'react'
import { useNavigate } from 'react-router-dom'

function Navbar() {
    const navigate = useNavigate()
    return (
        <header className="bg-delft rounded-md">
            <div className="flex items-center justify-end pr-4">
                <button className=' w-24 h-10 rounded-full m-2
                 text-columbia hover:text-lg' onClick={() => navigate('/SignUp')} >
                    Sign Up
                </button>
                <button className='bg-lightDelft text-columbia
                 w-24 h-10  rounded-full' onClick={() => navigate('/Login')}>
                    Log in
                </button>
            </div>
        </header>
    )
}

export default Navbar