import React from 'react';
import Card from './Card';

function Artists(props) {

    return (
        <div className='bg-delft rounded-md'>
            <div className='pt-5 ps-4'>
                <h1 className='text-2xl text-columbia'>Popular artists</h1>
            </div>
            <div className='flex flex-wrap justify-center md:justify-start md:ml-5'>
                <Card img={'/JubinNautiyal.jpeg'} name={'Jubin Nautiyal'} url={''} />
                <Card img={'/B-Praak.jpg'} name={'B Praak'} url={''} />
                <Card img={'/shreya.jpg'} name={'Shreya Ghoshal'} url={'/songs'} />
                <Card img={'/DarshanRaval.jpg'} name={'Darshan Raval'} url={''} />
                <Card img={'/ArijitSingh.jpg'} name={'Arijit Singh'} url={''} />
                <Card img={'/AlkaYagnik.jpg'} name={'Alka Yagnik'} url={''} />
                <Card img={'/UditNarayan.jpg'} name={'Udit Narayan'} url={''} />
                <Card img={'/Dhvani.webp'} name={'Dhvani Bhanushali'} url={''} />
                <Card img={'/Neha.jpeg'} name={'Neha Kakkar'} url={''} />
            </div>
        </div>
    );
}

export default Artists;
