import React, { useState } from 'react';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your login logic here
        console.log('Email:', email);
        console.log('Password:', password);
    };

    return (
        <div className="min-h-screen bg-cadet flex justify-center items-center">
            <div className=" p-8 border border-lightDelft rounded shadow-md w-96">
                <h2 className="text-3xl text-columbia mb-6 text-center">Log in to your account</h2>
                <form onSubmit={handleSubmit}>
                    <div className='mb-4'>
                        <label htmlFor="email" className='block text-columbia text-lg'>
                            Email or Username
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="block w-full px-3 py-1 bg-cadet text-white border border-lightDelft rounded-md focus:outline-none"
                        />
                    </div>

                    <div className="relative">
                        <label htmlFor="password" className="text-columbia text-lg">
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="block w-full px-3 py-1 bg-cadet text-white border border-lightDelft rounded-md focus:outline-none pl-10"
                        />
                        <img
                            alt=''
                            src='/hide.png'
                            className="absolute top-11 transform -translate-y-1/2 right-3 cursor-pointer h-7"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    <a href="a" className="font-medium text-sm text-columbia hover:text-lightDelft">
                        Forgot your password?
                    </a>
                    <button type="submit" className="bg-columbia text-delft 
                     border hover:border-2 px-4 py-2 rounded-full w-full mb-3 mt-5">Log In</button>
                    <button type="button" className="text-columbia border border-lightDelft hover:border-columbia 
                         flex py-2 ps-5 rounded-full w-full mb-3">
                        <img alt='' src='/Google.png' className='h-6 pr-10' />
                        Continue with Google
                    </button>
                    <button type="button" className="text-columbia border border-lightDelft hover:border-white 
                         flex py-2 ps-4 rounded-full w-full">
                        <img alt='' src='/facebook.png' className='h-6 pr-8' />
                        Continue with Facebook
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;

// import React, { useState } from 'react';

// const Login = () => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Add your login logic here
//         console.log('Email:', email);
//         console.log('Password:', password);
//     };

//     return (
//         <div className="min-h-screen bg-cadet flex justify-center items-center">
//             <div className=" p-8 border border-lightDelft rounded shadow-md w-96">
//                 <h2 className="text-3xl text-columbia mb-6 text-center">Log in to your account</h2>
//                 <form onSubmit={handleSubmit}>
//                     <div className='mb-4'>
//                         <label htmlFor="email" className='block text-columbia text-lg'>
//                             Email or Username
//                         </label>
//                         <input
//                             id="email"
//                             name="email"
//                             type="email"
//                             autoComplete="email"
//                             required
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             className="block w-full px-3 py-1 bg-cadet text-white border border-lightDelft rounded-md focus:outline-none"
//                         />
//                     </div>

//                     <div>
//                         <label htmlFor="password" className="text-columbia text-lg">
//                             Password
//                         </label>
//                         <img alt='' src='/hide.png' className='absolute h-8 ' />
//                         <input
//                             id="password"
//                             name="password"
//                             type="password"
//                             autoComplete="current-password"
//                             required
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="block w-full px-3 py-1 bg-cadet text-white 
//                             border border-lightDelft rounded-md focus:outline-none"
//                         />
//                     </div>
//                     <a href="a" className="font-medium text-sm text-columbia hover:text-lightDelft">
//                         Forgot your password?
//                     </a>
//                     <button type="submit" className="bg-columbia text-delft 
//                      border hover:border-2 px-4 py-2 rounded-full w-full mb-3 mt-5">Log In</button>
//                     <button type="button" className="text-columbia border border-lightDelft hover:border-columbia 
//                          flex py-2 ps-5 rounded-full w-full mb-3">
//                         <img alt='' src='/Google.png' className='h-6 pr-10' />
//                         Continue with Google
//                     </button>
//                     <button type="button" className="text-columbia border border-lightDelft hover:border-white 
//                          flex py-2 ps-4 rounded-full w-full">
//                         <img alt='' src='/facebook.png' className='h-6 pr-8' />
//                         Continue with Facebook
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default Login;
// import React, { useState } from 'react';

// const Login = () => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Add your login logic here
//         console.log('Email:', email);
//         console.log('Password:', password);
//     };

//     return (
//         <div className="min-h-screen bg-cadet flex justify-center items-center">
//             <div className="p-8 border border-lightDelft rounded shadow-md max-w-md w-full">
//                 <h2 className="text-3xl text-columbia mb-6 text-center">Log in to your account</h2>
//                 <form onSubmit={handleSubmit}>
//                     <div className='mb-4'>
//                         <label htmlFor="email" className='block text-columbia text-lg'>
//                             Email or Username
//                         </label>
//                         <input
//                             id="email"
//                             name="email"
//                             type="email"
//                             autoComplete="email"
//                             required
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             className="block w-full px-3 py-1 bg-cadet text-white border border-lightDelft rounded-md focus:outline-none"
//                         />
//                     </div>

//                     <div className="mb-4">
//                         <label htmlFor="password" className="block text-columbia text-lg">
//                             Password
//                         </label>
//                         <input
//                             id="password"
//                             name="password"
//                             type="password"
//                             autoComplete="current-password"
//                             required
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="block w-full px-3 py-1 bg-cadet text-white border border-lightDelft rounded-md focus:outline-none"
//                         />
//                     </div>
//                     <a href="a" className="font-medium text-sm text-columbia hover:text-lightDelft block mb-4">
//                         Forgot your password?
//                     </a>
//                     <button type="submit" className="bg-columbia text-delft border hover:border-2 px-4 py-2 rounded-full w-full mb-3 mt-2">Log In</button>
//                     {/* <button type="button" className="text-columbia border border-lightDelft hover:border-columbia flex py-2 rounded-full w-full mb-3">
//                         <img alt='' src='/Google.png' className='h-6 pr-2 pl-3' />
//                         Continue with Google
//                     </button>
//                     <button type="button" className="text-columbia border border-lightDelft hover:border-white flex py-2 rounded-full w-full">
//                         <img alt='' src='/facebook.png' className='h-6 pr-2 pl-3    ' />
//                         Continue with Facebook
//                     </button> */}
//                     <button type="button" className="text-columbia border border-lightDelft hover:border-columbia flex py-2 rounded-full w-full mb-3">
//                         <img alt='' src='/Google.png' className='h-6 pr-2 pl-3' />
//                         <span>Continue with Google</span>
//                     </button>
//                     <button type="button" className="text-columbia border border-lightDelft hover:border-white flex py-2 rounded-full w-full">
//                         <img alt='' src='/facebook.png' className='h-6 pr-2 pl-3' />
//                         <span>Continue with Facebook</span>
//                     </button>


//                 </form>
//             </div>
//         </div>
//     );
// };

// export default Login;
